<template>
  <div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component
          :is="Component"
          :key="$route.name"
          v-if="$route.meta?.keepAlive"
        />
      </keep-alive>
      <component
        :is="Component"
        :key="$route.name"
        v-if="!$route.meta?.keepAlive"
      />
    </router-view>
    <van-tabbar
      v-model="active"
      route
      placeholder
    >
      <van-tabbar-item
        replace
        to="/star-list"
        icon="home-o"
      >
        <span>达人</span>
        <template #icon="props">
          <img
            :src="props.active ? require('@/assets/images/tab-kol-selected@2x.png') : require('@/assets/images/tab-kol@2x.png')"
          >
        </template>
      </van-tabbar-item>
      <van-tabbar-item
        replace
        to="/mcn-list"
        icon="search"
      >
        <span>机构</span>
        <template #icon="props">
          <img
            :src="props.active ? require('@/assets/images/tab-mcn-selected@2x.png') : require('@/assets/images/tab-mcn@2x.png')"
          >
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'Home',
  data() {
    return {
      active: 0
    };
  },
  created() {
  },
  methods: {
    onChange(index) {
      this.active = index;
    }
  }
};
</script>

<style lang="less">
.van-tabbar {
  left: auto;
  margin: auto 0;
  max-width: 960px;
}

.van-tabbar--fixed {
  left: auto !important;
}
</style>